.head {
    background-color: #86bcc2;
    color: white;
    padding: 10px;
    border-width: 3px;
    border-color: white;
    border-style: solid;
    border-radius: 15px;
    text-align: center;
    font-size: unset;
    font-weight: 800;
    font-family: sans-serif;
}

.content {
    padding: 10px;
}

.maincontent{
    display:grid;
    height:100%;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .left{
    padding:5px;
  }
  .right{
    padding:5px;
  }