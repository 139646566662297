.formfield {
    display: flex;
    align-items: center;
    padding-bottom: 11px;
}
.reqlabel{
    width: 120px;
}
.testResult{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.603);
    color: rgb(5, 5, 5);
}
.testResultError{
    width: 100%;
    background-color: red;
    color: white;   
}
.testResultSuccess{
    width: 100%;
    background-color: green;
    color: white;   
}